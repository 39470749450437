import { User } from 'oidc-client-ts';
import { BirCode, BircodeData, RoleData, RolePrivilege } from 'screens/context/stores/types';
import { Buffer } from 'buffer';
import { roleMapping } from 'screens/context/stores/types';

export interface LoginPayload {
  idp: string
  password: string
  flow: string
}

export interface UserContextEntry {
  bircode: string,
  company: string
}

/**
 * Extended user
 */
export class RpUser extends User {

  public decodedAccessToken: any;
  public extendedRoles: Set<string>;
  public rolePrivilege: RolePrivilege;
  public email: string | undefined;
  public ipn: string | undefined;
  public firstName: string | undefined;
  public lastName: string | undefined;
  public company: string | undefined;
  public userContext: Array<UserContextEntry>;

  constructor(user: User | null) {
    if (user == null) throw new Error('null user received from RpUser constructor');
    console.log(`LOG_ADMIN: oidc_ts send user ${JSON.stringify(user)}`);
    super(user);
    this.decodedAccessToken = this._getDecodedAccessToken(this.access_token);
    console.log(`LOG_ADMIN: decoded user token ${JSON.stringify(this.decodedAccessToken)}`);
    this.extendedRoles = this._getExtendedRoles(this.decodedAccessToken);
    console.log(`LOG_ADMIN: extendedRoles ${JSON.stringify([...this.extendedRoles])}`);
    this.rolePrivilege = this._getRolePrivilege();
    console.log(`LOG_ADMIN: rolePrivilege ${JSON.stringify(this.rolePrivilege)}`);
    this.userContext = this._getUserContext();
    console.log(`LOG_ADMIN: userContext ${JSON.stringify(this.userContext)}`);
    this.email = user.profile.email || (!!user.profile.mail ? user.profile.mail as string : undefined);
    this.ipn = user.profile.login || this.decodedAccessToken.uid || undefined; 
    this.firstName = this.decodedAccessToken.firstname || (!!user.profile.firstName ? user.profile.firstName as string : undefined);
    this.lastName = this.decodedAccessToken.lastname || (!!user.profile.lastName ? user.profile.lastName as string : undefined);
    this.company = this.decodedAccessToken.company || (!!user.profile.siteName ? user.profile.siteName as string 
      : !!user.profile.siteShortName ? user.profile.siteShortName as string
      : !!user.profile.company ? user.profile.siteShortName as string
      : undefined);
  }

  // public

  get bircodeFromUser(): BirCode {

    // Okta
    // case: user main profile is linked to an establishment
    // nb: default case if user has multiple context but hasn't selected one yet
    if (!!this.profile?.siteSourceId) {
      return this.profile.siteSourceId as string;
    }

    // legacy idp
    if (this.profile.entity_identifiers) {
      if (typeof this.profile.entity_identifiers === "string") {
        const tmp = this.profile.entity_identifiers.replace('[', '').replace(']', '').split(';');
        return tmp[tmp.length - 1];
      }
      else if (Array.isArray(this.profile.entity_identifiers)) {
        const tmp = this.profile.entity_identifiers[this.profile.entity_identifiers.length -1].split(';');
        return tmp[tmp.length - 1];
      }
      return undefined;
    }
    else if (this.decodedAccessToken.entity_identifiers) {
      if (typeof this.decodedAccessToken.entity_identifiers === "string") {
        const tmp = this.decodedAccessToken.entity_identifiers.replace('[', '').replace(']', '').split(';');
        return tmp[tmp.length - 1];
      }
      else if (Array.isArray(this.decodedAccessToken.entity_identifiers)) {
        const tmp = this.decodedAccessToken.entity_identifiers[this.decodedAccessToken.entity_identifiers.length -1].split(';');
        return tmp[tmp.length - 1];
      }
      return undefined;
    }

    // remaining Okta case: use does not have a siteSourceId, but has a non empty userContext
    // for now, we blindly select the first one

    const uc = this._getUserContext();
    if (uc.length > 0) return uc[0].bircode;

    return undefined;
  }

  public getRoleData (): RoleData {

    return {
      myRoleList: [...this.extendedRoles],
      myRolePrivilege: this.rolePrivilege
    };
  }

  public getBirCodeData (): BircodeData | undefined {

    let selectedBircodeData: BircodeData | undefined = undefined
    let bircodeDataString = localStorage.getItem("bircodeData");
    let myRoleData = this.getRoleData();
    if (bircodeDataString) selectedBircodeData = JSON.parse(bircodeDataString)
    if (myRoleData.myRolePrivilege === RolePrivilege.R1 && !bircodeDataString) {
        if (this.bircodeFromUser) {
            selectedBircodeData = {
                bircode: this.bircodeFromUser,
                businessName: "",
                city: ""
            }
        }
    }
    return selectedBircodeData;
  }

  // private

  private _getDecodedAccessToken(access_token: string): Object {

    const [header, payload, signature] = access_token.split('.');
    return JSON.parse(Buffer.from(payload, 'base64').toString('utf-8'));
  }

  private _getExtendedRoles(access_token: any): Set<string> {

    const roles: Set<string> = new Set();

    // add roles from caller irn
    if (this.profile?.roles && Array.isArray(this.profile?.roles)) {
      this.profile?.roles.forEach((x: string) => roles.add(x));
    }

    // add roles from third-party irns 
    try {
      // IDP/NetIQ
      if (!!access_token.scope) {
        for (const scope of access_token.scope) {
          if (!scope.startsWith('role')) continue;
          if (!access_token[scope]) continue;
          if (Array.isArray(access_token[scope])) {
            access_token[scope].forEach((x: string) => roles.add(x));
          }
          else {
            if (/\[.*\]/.exec(access_token[scope])) {
              const sanitizedRoles: Array<string> = access_token[scope].replace(/[\[\]\s]+/g, '').split(',');
              sanitizedRoles.forEach(r => roles.add(r));
            }
            else {
              roles.add(access_token[scope]);
            }
          }
        }
      }

      // Okta
      if (!!access_token.scp) {
        for (const scope of access_token.scp) {
          if (!scope.startsWith('role')) continue;
          if (!access_token[scope]) continue;
          if (Array.isArray(access_token[scope])) {
            access_token[scope].forEach((x: string) => roles.add(x));
          }
          else {
            roles.add(access_token[scope]);
          }
        }
      }
    }
    catch (e) {
      console.error(`unable to compute extended roles for access_token ${access_token}`);
    }

    return roles;
  }

  private _getRolePrivilege(): RolePrivilege {

    let rolePrivilege: RolePrivilege = RolePrivilege.NONE;

    for (const role of this.extendedRoles) {
        if (!roleMapping[role]) continue;
        if (roleMapping[role] > rolePrivilege) rolePrivilege = roleMapping[role];
    }

    return rolePrivilege;
  }

  private _getUserContext(): Array<UserContextEntry> {

    let ret: Array<UserContextEntry> = [];

    const distinctBircodes = new Set();

    try {
      if (!this.profile?.userContext) return ret;

      for (const entry of this.profile.userContext as Array<string>) {
        
        const [noop0, noop1, establishment] = entry.split(';');
        const [bircode, noop2, company] = establishment.split('|');
        
        if(distinctBircodes.has(bircode)) continue;
        distinctBircodes.add(bircode);

        ret.push({
          'bircode': bircode,
          'company': company
        });
      }
    } catch (e) {
      console.error('unable to parse userContext', e);
    }

    return ret;
  }
}

export interface LoginState {
  loading: boolean
  user?: RpUser
  isSidebarVisible: boolean
  flow: string
}