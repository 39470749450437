import { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { RightOutlined } from '@ant-design/icons';

import { loadingSelector } from './stores/selectors';
import { loginActions } from './stores/actions';
import { userIsAuthenticated } from 'screens/login/stores/selectors';
import {
  LoginWrapper,
  ContactWrapper,
  LoginButton,
  IconAdvisorWrapper,
  IconAdvisor,
  Contact,
  ByPhoneNumber,
  PhoneNumber,
  TimeSlots,
  WelcomeWrapper,
  WelcomeTitle,
  WelcomeMessage,
  LoginLinkButton
} from './Styles';
import styled from 'styled-components';
import { CallRate } from 'shared/components/Styles';

import iconAdvisor from 'assets/img/icon-advisor.svg';
import { myRoleListSelector } from 'screens/context/stores';

import { OLD_BACKOFFICE_URL, RPARTS_URL } from 'shared/utils/constants'
import { Divider } from 'antd';
import { AUTH_FLOWS } from 'shared/utils/constants';

const SmallLoginButton = styled(LoginButton)`
  min-height: 30px;
  padding: 5px 10px;
  font-size: 0.9em;
  background-color: #f5f5f5;
  color: #333;
  width: auto;
  min-width: 120px;
`;

interface InfoTextProps {
  fontSize?: string;
  bold?: boolean;
}

export const StyledInfoText = styled.p<InfoTextProps>`
  font-size: ${(props) => props.fontSize || '14px'};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  color: white;
  text-align: left;
  margin-top: 10px;
  width: 100%;
`;

export const Login: FC = () => {

  const [showLegacyFlows, setShowLegacyFlows] = useState(false);

  const isAuthenticated = useSelector(userIsAuthenticated);
  const role = useSelector(myRoleListSelector);
  const loading = useSelector(loadingSelector);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleConnect = (flow: string) => {
    dispatch(loginActions.authLogin({ 'flow': flow }));
  };

  const handleRedirectToOldBackoffice = () => window.location.href = OLD_BACKOFFICE_URL
  const handleRedirectToRparts = () => window.location.href = RPARTS_URL

  return (
    !isAuthenticated || role === undefined ?
      <LoginWrapper >
        <WelcomeWrapper>
          <WelcomeTitle>{t("loginPage.welcomeTilte")}</WelcomeTitle>
          <WelcomeMessage>{t("loginPage.welcomeMessage")}</WelcomeMessage>

          <LoginButton
            onClick={() => handleConnect(AUTH_FLOWS.OKTA)}
            size="large"
            loading={loading}
            icon={<RightOutlined />}
            title={t("loginPage.LogToBO") + " (Okta)"}
          >
            {t("Generic.Actions.LogIn") + " (Okta) "}
          </LoginButton>

          <Divider style={{ minWidth: '95%', width: '95%', borderTop: '1px solid rgba(255,255,255,0.5)' }}></Divider>

          {!showLegacyFlows && (
            <SmallLoginButton
              onClick={() => setShowLegacyFlows(true)}
              icon={<RightOutlined />}
            >
              {t("loginPage.oldLoginFlows")}
            </SmallLoginButton>
          )}

          {showLegacyFlows && (
            <>
              <StyledInfoText fontSize="18px" bold={true}>
                {t("loginPage.oktaPriorityMessageTitle")}
              </StyledInfoText>

              <StyledInfoText fontSize="16px" bold={false}>
                {t("loginPage.oktaPriorityMessageDisclaimer")}
              </StyledInfoText>

              <LoginButton
                onClick={() => handleConnect(AUTH_FLOWS.IDP_LEGACY)}
                size="large"
                loading={loading}
                icon={<RightOutlined />}
                title={t("loginPage.LogToBO") + " (IDP)"}
              >
                {t("Generic.Actions.LogIn") + " (IDP)"}
              </LoginButton>

              <Divider style={{ minWidth: '95%', width: '95%'}} />
              
              <LoginButton
                onClick={() => handleConnect(AUTH_FLOWS.IDP_TWIN)}
                size="large"
                loading={loading}
                icon={<RightOutlined />}
                title={t("loginPage.LogToBO") + " (Twin)"}
              >
                {t("Generic.Actions.LogIn") + " (Twin) "}
              </LoginButton>
              <Divider style={{ minWidth: '95%', width: '95%', borderTop: '1px solid rgba(255,255,255,0.5)' }} />
            </>
          )}
          
          <WelcomeMessage style={{ marginTop: '20px' }}>
            {t("loginPage.rpartsMessage")}
          </WelcomeMessage>

          <LoginLinkButton
            onClick={() => handleRedirectToRparts()}
            loading={loading}
            icon={<RightOutlined />}
            title={t("loginPage.GoToRparts")}
          >
            {t("Generic.Actions.LinkRparts")}
          </LoginLinkButton>
        </WelcomeWrapper>
        <ContactWrapper>
          <IconAdvisorWrapper>
            <IconAdvisor src={iconAdvisor} />
          </IconAdvisorWrapper>
          <Contact>{t("loginPage.contact")}</Contact>
          <ByPhoneNumber>{t("loginPage.byPhoneNumber")}</ByPhoneNumber>
          <PhoneNumber>{t("loginPage.phoneNumber")}</PhoneNumber>
          <CallRate>{t("loginPage.callRate")}</CallRate>
          <TimeSlots>{t("loginPage.timeSlots")}</TimeSlots>
        </ContactWrapper>
      </LoginWrapper> :
      <Navigate to={{ pathname: "/home" }}></Navigate>
  );
};
